import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Icon,
  Button,
  Image,
  Center,
  Box,
  Text,
} from "@chakra-ui/react";
import Logo from "../../images/logo.png";
import BGLogin from "../../images/bg-login.png";
import { AuthContext } from "../../contexts/Auth";
import { useToast } from "@chakra-ui/react";
import {
  TiSocialFacebook,
  TiSocialInstagram,
  TiSocialLinkedin,
  TiSocialYoutube,
} from "react-icons/ti";
const ForgetPassword = () => {
  const { forgetPassword, resetPassword, loading } = useContext(AuthContext);
  const toast = useToast();

  const [email, setEmail] = useState("");
  const [token, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showResetFields, setShowResetFields] = useState(false);

  const handleSubmitEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await forgetPassword(email);
      console.log("status:", response);
      if (response.status === 200) {
        setShowResetFields(true);
        
      } else {
        toast({
          title: "Deu algo errado",
          status: "error",
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (password && confirmPassword) {
      if (password === confirmPassword) {
        try {
          const response = await resetPassword(email, token, password);
        } catch (error) {
          console.log(error);
        }
      } else {
        toast({
          title: "As senhas não coincidem!",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
    }
  };
  return (
    <>
      <Stack
        maxH={"100vh"}
        direction={{ base: "column", md: "row" }}
        backgroundColor={"white"}
      >
        <Flex
          minH={"100vh"}
          flex={1}
          backgroundImage={BGLogin}
          alt={"Login Image"}
          objectFit={"cover"}
          backgroundRepeat={"no-repeat"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box>
            <Center>
              <Text
                fontWeight={600}
                fontSize={"86px"}
                color={"white"}
                lineHeight={"75px"}
              >
                Sua<br></br>área<br></br>Expert.
              </Text>
            </Center>
          </Box>
        </Flex>
        <Flex p={8} flex={1} align={"center"} justify={"center"}>
          <Stack spacing={2} w={"full"} maxW={"md"}>
            <Flex justifyContent={"center"} alignItems={"center"}>
              <Box>
                <Image src={Logo} maxWidth={"200px"}></Image>
              </Box>
            </Flex>

            <Box>
              
              {!showResetFields ? (
                <>
                  <Text
                    fontSize={18}
                    fontWeight={400}
                    paddingTop={2}
                    paddingBottom={2}
                  >
                    Insira seu email para receber o código de recuperação de
                    senha
                  </Text>
                  <FormControl id="email" marginTop={5} marginBottom={4}>
                    <Input
                      height={"64px"}
                      borderRadius={50}
                      placeholder={"Coloque seu email"}
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormControl>
                  <Stack spacing={3}>
                    <Button
                      height={"50px"}
                      marginTop={5}
                      borderRadius={50}
                      bgColor={"#05070B"}
                      type="submit"
                      onClick={handleSubmitEmail}
                      colorScheme={"green"}
                      variant={"solid"}
                    >
                      Enviar
                    </Button>
                  </Stack>
                </>
              ) : (
                <>
                  <Text
                    fontSize={18}
                    fontWeight={400}
                    paddingTop={2}
                    paddingBottom={2}
                  >
                    Insira o código enviado por email e digite a nova senha
                  </Text>
                  <FormControl id="token" marginTop={5} marginBottom={4}>
                    <Input
                      height={"64px"}
                      borderRadius={50}
                      placeholder={"Insira o código"}
                      type="text"
                      value={token}
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </FormControl>
                  <FormControl id="new-password" marginTop={5} marginBottom={4}>
                    <Input
                      height={"64px"}
                      borderRadius={50}
                      placeholder={"Nova senha"}
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </FormControl>
                  <FormControl
                    id="confirm-password"
                    marginTop={5}
                    marginBottom={4}
                  >
                    <Input
                      height={"64px"}
                      borderRadius={50}
                      placeholder={"Confirmar senha"}
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </FormControl>
                  <Stack spacing={3}>
                    <Button
                      height={"50px"}
                      marginTop={5}
                      borderRadius={50}
                      bgColor={"#05070B"}
                      type="submit"
                      onClick={handleResetPassword}
                      colorScheme={"green"}
                      variant={"solid"}
                    >
                      Enviar
                    </Button>
                  </Stack>
                </>
              )}
            </Box>
          </Stack>
        </Flex>
      </Stack>
    </>
  );
};

export default ForgetPassword;
